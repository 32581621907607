import { Component, OnInit } from '@angular/core';
import {
  EntityStatus,
  FloorSpaceModel,
  IncidentTabStatusFilter,
  initialStats,
  RoomSpaceModel,
  ScreenOrientations,
  SpaceStats,
} from '@models';
import { Store } from '@ngrx/store';
import { AppState, updateLocation } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@services';
import { ActivatedRoute } from '@angular/router';
import { SpaceDetailsClass } from '@abstraction';

@Component({
  selector: 'app-mobile-building-details',
  templateUrl: './building-details.component.html',
  styleUrl: './building-details.component.scss',
})
export class BuildingDetailsComponent extends SpaceDetailsClass implements OnInit {
  protected readonly ScreenOrientations = ScreenOrientations;
  tab: 'list' | 'incidents' = 'list';
  search = '';
  incidentTabStatusFilterEnum = IncidentTabStatusFilter;
  resolvedIncidentsLoading = false;
  buildingStats: SpaceStats = initialStats();
  floorsWithSortedRooms: { [key: string]: RoomSpaceModel[] } = {};

  constructor(
    public override store: Store<AppState>,
    public override dialog: MatDialog,
    public override appService: AppService,
    public override route: ActivatedRoute
  ) {
    super(store, dialog, appService, route);
    this.onLocationLoaded = this.dataNormalizer;
  }

  ngOnInit(): void {
    this.getStoreData();
  }

  private initializeBuildingStats(allFloors: FloorSpaceModel[]) {
    this.buildingStats = initialStats();
    allFloors.forEach(floor => {
      this.generateRoomStateData(floor);
    });
    this.normalizeIncidentsAndDevices();
    this.countActiveFloors(allFloors);
  }

  private dataNormalizer() {
    const allFloors = this.locationData.floors;
    if (allFloors) {
      this.initializeBuildingStats(allFloors);
    }
  }
  countActiveFloors(allFloors: FloorSpaceModel[]) {
    this.buildingStats.floors = allFloors.filter(({ status }) => status !== this.EntityStatus.Archived).length;
  }
  private isAlertRoom(room: RoomSpaceModel) {
    return room.status === EntityStatus.Active && room.incidentCountByStatuses.newCount;
  }

  private isInProgressRoom(room: RoomSpaceModel) {
    return (
      room.status === EntityStatus.Active &&
      !room.incidentCountByStatuses.newCount &&
      room.incidentCountByStatuses.inProgressCount
    );
  }

  private isPausedRoom(room: RoomSpaceModel) {
    return room.status === EntityStatus.Paused;
  }
  generateRoomStateData(floor: FloorSpaceModel) {
    const sortingRes: { [key: string]: RoomSpaceModel[] } = {
      alert: [],
      inProgress: [],
      good: [],
      paused: [],
    };

    if (floor.rooms) {
      this.buildingStats.rooms += floor.rooms.filter(({ status }) => status !== this.EntityStatus.Archived).length;
      floor.rooms.forEach(room => {
        if (room.status) {
          if (this.isPausedRoom(room)) {
            sortingRes['paused'].push(room);
            return;
          }
          if (this.isAlertRoom(room)) {
            sortingRes['alert'].push(room);
            return;
          }
          if (this.isInProgressRoom(room)) {
            sortingRes['inProgress'].push(room);
            return;
          }
          sortingRes['good'].push(room);
        }
      });
    }

    this.floorsWithSortedRooms[floor.id] = [
      ...sortingRes['alert'],
      ...sortingRes['inProgress'],
      ...sortingRes['good'],
      ...sortingRes['paused'],
    ];
  }

  private normalizeIncidentsAndDevices() {
    const incidents = this.locationData.incidentCountByStatuses;
    const devices = this.locationData.devicesByStatuses;

    this.buildingStats.incidentAlert = incidents?.newCount || 0;
    this.buildingStats.incidentInProgress = incidents?.inProgressCount || 0;
    this.buildingStats.deviceActive = devices?.activeCount || 0;
    this.buildingStats.devicePaused = devices?.pausedCount || 0;
  }

  pauseSite() {
    const isActive = this.locationData.status !== EntityStatus.Paused;
    const title = isActive ? 'Pause' : 'Resume';
    const description = `Are you sure you want to ${isActive ? 'pause' : 'resume'} the ${
      this.locationData.friendlyName
    }?`;
    const status = isActive ? EntityStatus.Paused : EntityStatus.Active;
    this.toggleSiteStatus(title, description, status);
  }

  private toggleSiteStatus(title: string, description: string, status: EntityStatus) {
    this.openConfirmationDialog({
      title,
      description,
      action: updateLocation({
        clientId: this.appService.currentClient,
        locationId: this.locationData.id,
        data: { status },
      }),
    });
  }
}
