@if (incident) { @let firmware = incident.device['tags']?.['firmware'] ;
<app-mobile-nested-page-header [title]="incident.incidentNumber.toString()" titleName="Ticket №:" />

<mat-card class="card">
  <ul class="main-details-list">
    <li>
      <span>Building: </span>
      <a class="underline" [routerLink]="['/dashboard', incident.device.location.id]"
        ><strong>{{ incident.device.location.friendlyName }}</strong></a
      >
    </li>
    <li>
      <span>Address: </span>
      {{ incident.device.location.address.streetAddress + ', ' + incident.device.location.address.city }}
    </li>
    <li>
      <span>Device Status: </span>
      <app-entity-status fontSize="base" [status]="incident.device.status" />
    </li>
  </ul>
</mat-card>

<mat-card class="card divider-card mt-3">
  <div>
    <div>Floor:</div>
    <div>
      <a
        class="underline text-xl font-semibold"
        [routerLink]="['/dashboard', incident.device.location.id, incident.device.spacePath?.[0]?.id]"
        >{{ incident.device.spacePath?.[0]?.friendlyName }}</a
      >
    </div>
  </div>
  <div class="divider">
    <div></div>
    <div class="line-block"></div>
    <div></div>
  </div>
  <div>
    <div>Room:</div>
    <div>
      <a
        class="underline text-xl font-semibold"
        [routerLink]="['/dashboard', incident.device.location.id, incident.device.spacePath?.[0]?.id, incident.device.spacePath?.[1]?.id]"
        >{{ incident.device.spacePath?.[1]?.friendlyName }}</a
      >
    </div>
  </div>
</mat-card>

<div class="border-b-ui-gray-light border-b flex items-center justify-between my-3">
  <mat-button-toggle-group
    class="mobile-toggle-group"
    aria-label="Incident Tabs"
    [value]="tab"
    (change)="tab = $event.value">
    <mat-button-toggle value="ticket" class="test-ticket-tab-btn">
      <span class="item-status-title">Ticket Info</span>
    </mat-button-toggle>
    <mat-button-toggle value="device" class="test-device-tab-btn">
      <span class="item-status-title">Device Info</span>
    </mat-button-toggle>
    <mat-button-toggle value="notes" class="test-notes-tab-btn">
      <span class="item-status-title">Notes</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<mat-card class="test-ticket-tab card" [class]="tab === 'ticket' ? 'visible' : 'is-hidden-tab'">
  <h4 class="font-poppins text-2xl text-ui-dark font-semibold mb-4">{{ incident.incidentNumber }}</h4>
  <ul class="incident-details-list">
    <li>
      <span>Incident Status:</span>
      <p>
        <app-incident-status [status]="incident.status" />
      </p>
    </li>
    <li>
      <span>Fault Name:</span>
      <p>{{ incident.fault.name }}</p>
    </li>
    <li>
      <span>Fault Description:</span>
      <p>{{ incident.fault.description }}</p>
    </li>
  </ul>
</mat-card>

<mat-card class="test-device-tab card" [class]="tab === 'device' ? 'visible' : 'is-hidden-tab'">
  <h4 class="font-poppins text-2xl text-ui-dark font-semibold mb-4">
    <a class="underline" [routerLink]="['/devices', incident.device.location.id, incident.device.id]">{{
      incident.device.friendlyName
    }}</a>
  </h4>

  <div>
    <app-img-loader
      loader="skeleton"
      *ngIf="incident.device.deviceModelInformation && incident.device.deviceModelInformation.documentReference"
      altText="device"
      errorSrc="assets/icons/image-error.svg"
      [imgSrc]="
        'clientId/' +
        appService.currentClient +
        '/library/standardMake/' +
        incident.device.deviceModelInformation.make.id +
        '/standardModel/' +
        incident.device.deviceModelInformation.id +
        '/document/' +
        incident.device.deviceModelInformation.documentReference
      " />
    <img
      alt="Device Image"
      *ngIf="!incident.device.deviceModelInformation?.documentReference"
      src="assets/icons/image-placeholder.svg" />
  </div>

  <ul class="incident-details-list mb-3">
    <li>
      <span>Device type:</span>
      <p>{{ incident.device.deviceModelInformation?.deviceType }}</p>
    </li>
    <li>
      <span>Manufacturer: </span>
      <p>{{ incident.device.deviceModelInformation?.make?.name }}</p>
    </li>
    <li>
      <span>Model: </span>
      <p>{{ incident.device.deviceModelInformation?.name }}</p>
    </li>
    <li>
      <span>Serial №: </span>
      <p>{{ incident.device.physicalDeviceId }}</p>
    </li>

    @if (firmware) {
    <li>
      <span>Firmware: </span>
      <p>{{ firmware }}</p>
    </li>
    }

    <li>
      <span>Total Device Incidents: </span>
      <p>No data in response</p>
    </li>
  </ul>
  <a
    class="btn-medium btn-green w-full"
    [routerLink]="['/devices', incident.device.location.id, incident.device.id]"
    [queryParams]="{ tab: 'incidents' }"
    >Show all device incidents</a
  >
</mat-card>

<div class="test-notes-tab incident-notes" [class]="tab === 'notes' ? 'visible' : 'is-hidden-tab'">
  <mat-form-field class="w-full input-main bg-opaque h-auto">
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      placeholder="Add Comment..."
      [(ngModel)]="noteDescription"></textarea>
  </mat-form-field>
  <div *ngIf="noteDescription" class="mb-6 flex items-center">
    <button
      [disabled]="!noteDescription.trim().length || postNoteLoading"
      (click)="addNote()"
      class="test-save-note-btn btn-green btn-small mr-2">
      <mat-spinner *ngIf="postNoteLoading" diameter="20" color="primary" class="mr-1"></mat-spinner>
      Save
    </button>
    <button (click)="noteDescription = ''" class="test-note-clear-btn px-4 py-2">Cancel</button>
  </div>

  <div *ngFor="let note of notes; let i = index" class="mb-6">
    <div class="flex justify-between items-center mb-3">
      <strong class="text-ui-dark font-nunito text-xl" *ngIf="note.user"
        >{{ note.user.lastName }} {{ note.user.firstName }}</strong
      >
      <span class="font-nunito text-ui-gray-100 text-sm">{{ note.timeStamp | date : 'medium' }}</span>
    </div>
    <p class="text-base text-ui-gray-100 font-nunito">
      {{ note.textValue }}
    </p>
  </div>
</div>
} @else {
<app-mobile-space-skeleton-animation class="test-skeleton-animation" />
}
