<app-spaceplan
  *ngIf="currentFloor && locationId"
  [spaceplan]="{
    id: currentFloor.id,
    documentReference: currentFloor.documentReference,
    attachments: currentFloor.rooms || [],
    parentSpaceId: currentFloor.parentSpaceId
  }"
  [locationId]="locationId"
  [currentAttachmentId]="roomId"
  [canEdit]="false"
  [popover]="roomPopover"
  [isMobile]="true" />
