import { Component, Input } from '@angular/core';
import { FloorSpaceModel } from '@models';
import { RoomPopoverComponent } from '@standalone/room-popover/room-popover.component';

@Component({
  selector: 'app-mobile-floorplan-tab',
  templateUrl: './floorplan-tab.component.html',
})
export class FloorplanTabComponent {
  @Input() currentFloor: FloorSpaceModel | undefined;
  @Input() locationId: string | undefined;
  @Input() roomId = '';

  roomPopover = RoomPopoverComponent;
}
