@if (locationData) { @let isLandscape = appService.screenOrientation === ScreenOrientations.LANDSCAPE ;
<header class="flex">
  <app-mobile-nested-page-header class="flex-grow" [title]="locationData.friendlyName" titleName="Building" />
  <app-mobile-mobile-buttons-bar
    [isPaused]="locationData.status === EntityStatus.Paused"
    [isArchived]="locationData.status === EntityStatus.Archived"
    (statusTrigger)="pauseSite()" />
</header>

<app-mobile-building-address-bar
  [buildingAddress]="locationData.address.streetAddress + ', ' + locationData.address.city"
  [buildingName]="locationData.friendlyName"
  [buildingStatus]="locationData.status" />
<div [class.flex]="isLandscape">
  <mat-card [class.mr-2]="isLandscape" class="flex-grow card divider-card mt-3">
    <div>
      <div>Total Floors:</div>
      <div class="test-total-floors">
        <strong>{{ buildingStats.floors }}</strong>
      </div>
    </div>
    <div class="divider">
      <div></div>
      <div class="line-block"></div>
      <div></div>
    </div>
    <div>
      <div>Total Rooms:</div>
      <div class="test-total-rooms">
        <strong>{{ buildingStats.rooms }}</strong>
      </div>
    </div>
  </mat-card>
  <app-mobile-incident-info-block [class.ml-2]="isLandscape" class="flex-grow" [spaceStats]="buildingStats" />
</div>
<div class="border-b-ui-gray-light border-b flex items-center justify-between mb-3 pt-4">
  <mat-button-toggle-group
    class="mobile-toggle-group"
    aria-label="Dashboard Tabs"
    [value]="tab"
    (change)="tab = $event.value">
    <mat-button-toggle value="list">
      <span class="item-status-title">Building Plan</span>
    </mat-button-toggle>
    <mat-button-toggle value="incidents">
      <span class="item-status-title">Building Incidents</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="test-building-plan-content" [hidden]="tab === 'incidents'">
  <div class="pb-3.5">
    <app-search-input placeholder="Floor name" inputWidth="100%" (searchDataEvent)="search = $event" />
  </div>
  @if (locationData.floors | filterFloorSpaceByName : search; as floors) { @if (floors.length) {
  <div class="flex justify-between pb-2 text-ui-gray-450 text-xs">
    <div><strong>Floor Name:</strong></div>
    <div><strong> Rooms:</strong></div>
  </div>
  @for (item of floors; track item.friendlyName) {
  <app-mobile-building-list-item [locationId]="locationId" [floorData]="item" />
  } } @else {
  <div class="text-base text-ui-gray-100 py-2 text-center">No Results</div>
  } }
</div>

<div class="test-incidents-content" [hidden]="tab === 'list'">
  <app-mobile-incidents-tab [locationId]="locationId" />
</div>
} @else {
<app-mobile-space-skeleton-animation />
}
