<mat-card class="card divider-card mt-3">
  <div>
    <div>Incidents:</div>
    <div>
      <app-incident-status-count [status]="IncidentStatus.IN_QUEUE" [amount]="spaceStats.incidentAlert" />
      <app-incident-status-count [status]="IncidentStatus.IN_PROGRESS" [amount]="spaceStats.incidentInProgress" />
    </div>
  </div>
  <div class="divider">
    <div></div>
    <div class="line-block"></div>
    <div></div>
  </div>
  <div>
    <div>Devices:</div>
    <div>
      <span class="text-ui-green-light">{{ spaceStats.deviceActive }}</span> | {{ spaceStats.devicePaused }}
    </div>
  </div>
</mat-card>
